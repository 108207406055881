.exploresofasection {
  padding: 40px 0px;
  background-color: #f5faff;
  
  .flexContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .exploresofaheading {
      margin-bottom: 20px;
      text-align: left;

      .subtitle {
          font-size: 28px;
          line-height: 32px;
          color: #333333;
          font-family: 'pangrammedium';
          margin: 0px 0px 4px;
      }

      h2 {
          font-size: 28px;
          line-height: 32px;
          color: #333333;
          font-family: 'pangrammedium';
          margin: 0px 0px 4px;
      }

      p {
          font-size: 14px;
          line-height: 20px;
          font-family: 'pangramregular';
          color: #515151;
          letter-spacing: 0.02em;
          margin: 0px;
      }

  }
  
    .viewAll {
      border: 1px solid #d1d1d1;
      border-radius: 4px;
      padding: 10px 15px;
      display: flex;
      align-items: center;
      font-family: 'pangrammedium';
      text-transform: uppercase;
      line-height: 24px;
      background: #f5f5f5;
      font-size: 14px;
      transition: 0.3s all ease-in-out;
      color: #333333;
      cursor: pointer;
    }
  }

    .exploresofaheading {
        margin-bottom: 20px;
        text-align: center;

        .subtitle {
            font-size: 28px;
            line-height: 32px;
            color: #333333;
            font-family: 'pangrammedium';
            margin: 0px 0px 4px;
        }

        h2 {
            font-size: 28px;
            line-height: 32px;
            color: #333333;
            font-family: 'pangrammedium';
            margin: 0px 0px 4px;
        }

        p {
            font-size: 14px;
            line-height: 20px;
            font-family: 'pangramregular';
            color: #515151;
            letter-spacing: 0.02em;
            margin: 0px;
        }

    }

    .exploresofacard {
        display: block;
        margin-bottom: 20px;
        background-color: #fff;
        padding: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
        transition: .3s all ease;
        border-radius: 4px;

        .exploresofaimg {
            figure {
                position: relative;
                overflow: hidden;
                border-radius: 3px;

                &:before {
                    content: "";
                    padding-top: 86%;
                    display: block;
                }

                img {
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    transition: 0.3s all ease-in-out;
                    transform: scale(1);
                    margin: auto;
                    width: auto;
                    height: auto;
                    border-radius: 3px;
                }
            }
        }

        .exploresofatext {
            padding: 8px;
            text-align: center;

            p {
                font-size: 13px;
                line-height: 20px;
                color: #515151;
                letter-spacing: .05em;
                margin: 0px;
                font-family: 'pangramregular';
            }

            h4 {
                font-family: "pangrammedium";
                font-size: 15px;
                line-height: 20px;
                color: #333333;
                letter-spacing: .05em;
                margin: 0px 0px 3px;
            }

            .prices {
                font-family: "pangrammedium";
                font-size: 15px;
                line-height: 20px;
                color: #333333;
                letter-spacing: .05em;
                margin: 0px 0px 3px;
            }
        }

        &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
            margin-top: -3px;
        }
    }
}