.exploresofasection {
    padding: 40px 0px;
    background-color: #f5faff;

    .exploresofainner {
        max-width: 96%;
        margin: auto;
    }

    .exploresofaheading {
        margin-bottom: 20px;
        text-align: center;

        .subtitle {
            font-size: 28px;
            line-height: 32px;
            color: #333333;
            font-weight: 500;
            font-family: pangrammedium;
            margin: 0px 0px 4px;
        }

        h2 {
            font-size: 28px;
            line-height: 32px;
            color: #333333;
            font-weight: 500;
            font-family: pangrammedium;
            margin: 0px 0px 4px;
        }

        p {
            font-size: 14px;
            line-height: 20px;
            font-family: pangramregular;
            color: #515151;
            font-weight: 400;
            letter-spacing: 0.02em;
            margin: 0px;
        }

    }

    .exploresofacard {
        display: block;
        margin-bottom: 20px;
        background-color: #fff;
        padding: 4px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, .1);
        transition: .3s all ease;
        border-radius: 4px;

        .exploresofaimg {
            figure {
                position: relative;
                overflow: hidden;
                border-radius: 3px;

                &:before {
                    content: "";
                    padding-top: 86%;
                    display: block;
                }

                img {
                    position: absolute;
                    right: 0;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    transition: 0.3s all ease-in-out;
                    transform: scale(1);
                    margin: auto;
                    width: auto;
                    height: auto;
                    border-radius: 3px;
                }
            }
        }

        .exploresofatext {
            padding: 8px;
            text-align: center;

            p {
                font-size: 13px;
                line-height: 20px;
                color: #515151;
                letter-spacing: .05em;
                font-weight: 300;
                margin: 0px;
                font-family: pangramregular;
            }

            h4 {
                font-family: "pangrammedium";
                font-size: 15px;
                line-height: 20px;
                color: #333333;
                font-weight: 500;
                letter-spacing: .05em;
                margin: 0px 0px 3px;
            }

            .prices {
                font-family: "pangrammedium";
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                font-weight: 500;
                letter-spacing: .05em;
                margin: 0px 0px 3px;
            }
        }

        &:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, .1);
            margin-top: -3px;
        }
    }
}

@media (max-width: 767px) {
    .exploresofasection {
        .exploresofaheading {
            .subtitle {
                font-size: 1.25rem;
                line-height: 24px;
                margin: 0px;
            }

            p {
                font-size: 0.8125rem;
                line-height: 18px;
                margin: 2px auto 0;
            }
        }

        .exploresofacontent {
            display: grid;
            grid-template-rows: repeat(1, auto);
            grid-auto-flow: column;
            grid-auto-columns: minmax(190px, 1fr);
            gap: 10px;
            overflow: auto !important;
            margin-bottom: 10px;

            &::-webkit-scrollbar {
                display: none;
            }

            .exploresofacard {
                width: 100%;
                display: inline-block;
                white-space: normal;
                padding: 0;
                margin-right: 10px;
                margin-bottom: 15px;
            }
        }
    }
}


.viewmorebtndiv {
    text-align: center;
    margin-top: 15px;

    .view-all-furni {
        padding: 8px 20px;
        font-size: 14px;
        line-height: 18px;
        background: transparent;
        color: #515151;
        border: 1px solid #8b8b8b;
        border-radius: 4px;
        font-family: "pangrammedium";
        text-transform: capitalize;
    }
}