.sofasetsection {
  padding-top: 50px;

  .sofasetheading {
    text-align: center;
    margin-bottom: 25px;

    h2 {
      font-size: 28px;
      line-height: 32px;
      color: #333333;
      font-weight: 500;
      font-family: pangrammedium;
      margin: 0px 0px 4px;
    }

    h1 {
      padding: 0;
      font-size: 1.625rem;
      line-height: 40px;
      color: #333333;
      font-weight: 500;
      font-family: pangrammedium;
      margin: 0px 0px 4px;
    }

    .heading {
      display: inline-block;
      padding: 0;
      font-size: 32px;
      line-height: 36px;
      color: #333333;
      font-weight: 500;
      font-family: 'Abhay-Libre Bold';
      margin: 0px 0px 4px;
    }

    .catHeading {
      display: inline-block;
      padding: 0;
      font-size: 1.5rem;
      line-height: 26px;
      color: #333333;
      font-weight: 400;
      // font-family: 'Abhay-Libre Bold';
      margin: 0px 0px 4px;
    }

    .catDescription {
      width: 850px;
      padding: 0 10px;
      margin: 0px auto;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      font-family: pangramregular;
      color: #515151;
      font-weight: 400;
      letter-spacing: 0.02em;
      position: relative;
      // overflow: hidden;
      min-height: 20px;
      height: 100%;
      margin: 0px auto;
      max-width: 650px;

      b {
        font-weight: 500;
        color: #333;
        font-size: 15px;
      }
    }

    .readmore {
      color: #e57200;
      font-size: 14px;
      position: absolute;
      right: 0px;
      z-index: 1;
      top: 0px;
      background-color: #fff;
      padding: 1px 4px;
      cursor: pointer;
    }
  }

  .sofasetcontent {
    padding-bottom: 20px;
    @media (min-width: 767px) {
      border-bottom: 1px solid #e5e5e5;
    }
  }

  .sofasetcard {
    display: block;
    margin-bottom: 20px;
    text-align: center;

    figure {
      position: relative;
      overflow: hidden;
      background: #f5f5f5;
      margin-bottom: 10px;
      background: #fff !important;
      display: block;
      margin: 0px;

      &::before {
        content: '';
        display: block;
        padding-top: 86% !important;
      }

      img {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: auto;
        // height: auto;
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: 0.3s all ease-in-out;
        transition: 0.3s all ease-in-out;
      }
    }
    .sofasettext {
      font-size: 16px;
      line-height: 20px;
      color: #333;
      margin: 0px 0px;
      padding: 10px 0px 5px;
      font-family: 'pangramregular';
    }

    &:hover {
      .sofasettext {
        color: #e57200;
      }
    }
  }
}

.mx-650 {
  width: 650px;
  margin: 0px auto;
}
